export default [
  // GENERAL MODULE
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    code: 'common',
  },
  {
    title: 'Report dashboard',
    route: 'report-page',
    icon: 'PieChartIcon',
    code: 'report_read',
  },
  // SALE MODULE
  {
    title: 'Sale manager',
    icon: 'TrendingUpIcon',
    code: 'sale_read' || 'sale_create' || 'sale_update'|| 'sale_delete' || 'sale_manage'  || 'sale_deactivate',
    children: [{
      title: 'Sale campaign',
      route: 'sale-campaign',
      code: 'sale_read' || 'sale_create' || 'sale_update'|| 'sale_delete' || 'sale_manage'  || 'sale_deactivate',
    },
    {
      title: 'Coupon',
      route: 'coupon-manager',
      code: 'coupon_create' || 'coupon_read' || 'coupon_update'|| 'coupon_delete',
    },
    ],
  },
  // RBAC MODULE
  {
    title: 'User manager',
    icon: 'UserIcon',
    code: 'ur_read' || 'ur_manage' || 'ur_create' ||  'ur_update' ||  'ur_delete' ||  'ur_deactivate',
    children: [{
      title: 'User list',
      route: 'user-manager',
      code: 'ur_read',
    },
    {
      title: 'Permission controller',
      route: 'rbac',
      code: 'role_read' && 'per_read',
    },
    ],
  },
  // PRODUCT MODULE
  {
    title: 'Offering manager',
    icon: 'BoxIcon',
    code:  'product_create' || 'product_read' || 'product_update' || 'product_delete' || 'product_approve' || 'product_deactivate',
    children: [
      {
        title: 'Asset',
        route: 'asset-manager',
        code: 'product_read',
      },
      {
        title: 'E-learnings',
        route: 'elearn-manager',
        code: 'product_read',
      },
      {
        title: 'Event',
        route: 'event-manager',
        code: 'product_read',
      },
      {
        title: 'Publications',
        route: 'publication-manager',
        code: 'product_read',
      },
      {
        title: 'Spotlight setting',
        route: 'spotlight-manager',
        code:  'category_create' ||  'category_read' ||  'category_update' ||  'category_delete',
      },
      // {
      //   title: 'Spotlight custom',
      //   route: 'spotlight-custom',
      //   code: 'read_spotlight',
      // },
    ],
  },
  // Bussiness manager
  {
    title: 'Bussiness challenge',
    route: 'bussiness-manager',
    icon: 'BriefcaseIcon',
    code: 'tag_read' || 'tag_manage' || 'tag_create' || 'tag_read' || 'tag_update' || 'tag_delete',
  },
  // Role manager
  {
    title: 'Role manager',
    route: 'role-manager',
    icon: 'CodepenIcon',
    code: 'tag_read' || 'tag_manage' || 'tag_create' || 'tag_read' || 'tag_update' || 'tag_delete',
  },
  // TAG MANAGER
  {
    title: 'Tag manager',
    route: 'tag-manager',
    icon: 'TagIcon',
    code: 'tag_read' || 'tag_manage' || 'tag_create' || 'tag_read' || 'tag_update' || 'tag_delete',
  },
  // CATEGORY MANAGER
  {
    title: 'Category manager',
    route: 'category-manager',
    icon: 'ArchiveIcon',
    code:  'category_create' ||  'category_read' ||  'category_update' ||  'category_delete',
  },
  // MEDIA MODULE
  {
    title: 'Media manager',
    route: 'media-manager',
    icon: 'ImageIcon',
    code: 'media_create' || 'media_update' || 'media_delete' || 'media_read',
  },
  // REGION MODULE
  {
    title: 'Region config',
    route: 'region-config',
    icon: 'FlagIcon',
    code: 'region_create' || 'region_update' || 'region_delete' || 'region_read',
  },
    // REGION MODULE
  {
    title: 'Top bar',
    route: 'top-bar',
    icon: 'HashIcon',
    code: 'region_create' || 'region_update' || 'region_delete' || 'region_read',
  },
  {
    title: 'Mailchimp',
    icon: 'MailIcon',
    code:  'mail_read' || 'mail_update' || 'mail_delete',
    children: [{
      title: 'Account',
      route: 'mailchimp-manager',
      code: 'mail_read',
    },
    {
      title: 'Templates',
      route: 'mailchimp-templates',
      code: 'mail_read',
    },
    {
      title: 'Mail action',
      route: 'mail-action',
      code: 'mail_read',
    },
    ],
  },
  {
    title: 'Payment',
    icon: 'CreditCardIcon',
    code: 'pm_read' || 'oder_read',
    children: [
      {
        title: 'Order manager',
        route: 'order-manager',
        code: 'order_read',
      },
      // {
      //   title: 'Payment section',
      //   route: 'payment-section',
      //   code: 'pm_read',
      // },
    ],
  },
  {
    title: 'Third party config',
    route: 'third-party-config',
    icon: 'SettingsIcon',
    code: 'thirdparty_read',
  },

  {
    title: 'Tracking',
    route: 'tracking',
    icon: 'TvIcon',
    code: 'tracking_read',
  },
  {
    title: 'Notification',
    route: 'notification',
    icon: 'BellIcon',
    code: 'tracking_read',
  },
]
