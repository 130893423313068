<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
      </div>
    </li>

    <!-- Notifications -->
    <div class="scrollable-container media-list scroll-area">
      <!-- Account Notification -->
      <b-link
        v-for="notification in dataNotifies"
        :key="notification.id"
      >
        <b-media>
          <div
            class="media-heading d-flex"
          >
            <div class="font-weight-bolder mr-auto">
              <span>
                [{{ notification.role.name }}] {{ notification.actor.email }}
              </span>
              <br>
              <small>{{ notification.description }}</small>
            </div>
            <span class="ml-2">
              {{ formatDate(notification.createdAt) }}
            </span>
          </div>
        </b-media>
      </b-link>
    </div>

    <!-- Cart Footer
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Read all notifications</b-button>
    </li> -->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BMedia,
  BLink,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ref } from '@vue/composition-api'
import moment from 'moment'
import { formatDate } from '@/utils/utils'
import * as config from '@/utils/config'

export default {
  components: {
    BNavItemDropdown,
    BMedia,
    BLink,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  setup() {
    const dataNotifies = ref([])
    const fetchNotify = () => {
      store
        .dispatch('app/fetchNotify', {
          page: 1,
          take: 10,
          order: 'DESC',
        })
        .then(response => {
          dataNotifies.value = response.body.data
        })
        .catch(error => {
          console.log(error)
        })
    }
    setTimeout(()=>{
      fetchNotify()
    }, 3000)
    setInterval(() => {
      if (localStorage.getItem(config.TOKEN_KEY)) {
        fetchNotify()
      }
    // }, 2 * 60 * 1000)
    }, 5 * 60 * 1000)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      formatDate,
      dataNotifies,
      perfectScrollbarSettings,
    }
  },
  methods: {
    formatDateDDMMYYYY(time) {
      return moment(time).format('DD/MM/YYYY')
    },
  },
}
</script>

<style>

</style>
