<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <v-select v-model="checked" class="mr-1" multiple :options="options" :loading="options.length === 0" bordered
        @input="checkEle">
        <template #option="{ label, icon }">
          <b-img :src="icon" class="mr-1" height="14px" width="22px" />
          <span> {{ label }}</span>
        </template>
      </v-select>
      <notification-dropdown />
      <locale />
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{
                userLogin.firstName
                ? `${userLogin.firstName} ${userLogin.lastName}`
                : "Admin"
              }}
            </p>
            <span class="user-status">{{ userLogin.jobTitle }}</span>
          </div>
          <b-avatar size="40" variant="light-primary" badge :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal" badge-variant="success" />
        </template>
        <!-- <b-dropdown-item link-class="d-flex align-items-center" :to="{ name: 'user-profile' }">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item> -->

        <!-- <b-dropdown-divider /> -->

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BFormCheckboxGroup,
  BDropdown,
  BImg,
  BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store/index'
import * as config from '@/utils/config'
import { mapGetters } from 'vuex'
import Locale from './Locale.vue'
import NotificationDropdown from './NotificationDropdown.vue'

export default {
  components: {
    NotificationDropdown,
    BFormCheckbox,
    BImg,
    BDropdown,
    BFormCheckboxGroup,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // Navbar Components
    Locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  computed: {
    ...mapGetters('authenticate', ['userLogin']),
  },
  beforeMount() {

  },

  data() {
    const defaultRegion = [
      {
        label: 'Malaysia',
        code: 'my',
        icon: require('@/assets/images/flags/my.png'),
      },
      {
        label: 'Vietnam',
        code: 'vn',
        icon: require('@/assets/images/flags/vn.png'),
      },
      {
        label: 'Indonesia',
        code: 'id',
        icon: require('@/assets/images/flags/id.png'),
      },
      {
        label: 'Singapore',
        code: 'sg',
        icon: require('@/assets/images/flags/sg.png'),
      },
      {
        label: 'Thailand',
        code: 'th',
        icon: require('@/assets/images/flags/th.png'),
      },
      {
        label: 'South Korea',
        code: 'kr',
        icon: require('@/assets/images/flags/kr.png'),
      },
      {
        label: 'Japan',
        code: 'jp',
        icon: require('@/assets/images/flags/jp.png'),
      },
    ]
    return {
      options: [],
      checked: [],
      defaultRegion,

    }
  },

  mounted() {
    setInterval(() => {
      if (localStorage.region && localStorage.regionAccept) {
        this.options = this.defaultRegion.filter(x => localStorage.region.split(',').includes(x.code))
        this.checked = this.defaultRegion.filter(x => localStorage.regionAccept.split(',').includes(x.code))
      }
    }, 3000)
  },
  methods: {

    async logout() {
      await store.dispatch('authenticate/logoutLocal').then(() => {
        store.dispatch('authenticate/logout')
        this.$acl.user.permissions = []
        this.$router.push({ name: 'login' })
      })
    },
    checkEle() {
      const newCheck = this.checked.map(x => x.code)
      localStorage.setItem(config.REGION_ACEPT, newCheck.join(','))
      window.location.reload()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
